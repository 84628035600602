import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { Layout } from "./components/layout/Layout";
import LoadingSpinner from "./components/loader/LoadingSpinner";
import BranchHome from "./pages/branch-page/BranchHome";
import ServicesListing from "./pages/services-listing/ServicesListing";

// Lazy loading the components
const Home = lazy(() => import("./pages/home-page/Home"));
const AboutUs = lazy(() => import("./pages/about-page/AboutUs"));


const AppRoutes = () => {
  return (

    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/branch/:slug" element={<BranchHome />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<ServicesListing />} />
      </Routes>
    </Suspense>

  );
};

export default AppRoutes;
