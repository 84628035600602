import React, { useState } from "react";
import classes from "./Services.module.css";

const services = [
  {
    _id: "3452454",
    name: "Infertility Treatment",
    icon: "/img/sample/infertility-icon.svg",
  },
  {
    _id: "3452454",
    name: "Critical Care",
    icon: "/img/sample/critical.svg",
  },
  {
    _id: "3452454",
    name: "Infertility Treatment",
    icon: "/img/sample/infertility-icon.svg",
  },
  {
    _id: "3452454",
    name: "Infertility Treatment",
    icon: "/img/sample/infertility-icon.svg",
  },
  {
    _id: "3452454",
    name: "Infertility Treatment",
    icon: "/img/sample/infertility-icon.svg",
  },
  {
    _id: "3452454",
    name: "Infertility Treatment",
    icon: "/img/sample/infertility-icon.svg",
  },
  {
    _id: "3452454",
    name: "Orthopaedics Treatment",
    icon: "/img/sample/ortho.svg",
  },
  {
    _id: "3452454",
    name: "Gynaecology",
    icon: "/img/sample/gynaecology.svg",
  },
  {
    _id: "3452454",
    name: "General, Bariatric & Paediatric Surgery General, Bariatric & Paediatric Surgery",
    icon: "/img/sample/infertility-icon.svg",
  },
  {
    _id: "3452454",
    name: "Infertility Treatment",
    icon: "/img/sample/infertility-icon.svg",
  },
  {
    _id: "3452454",
    name: "Infertility Treatment",
    icon: "/img/sample/infertility-icon.svg",
  },
  {
    _id: "3452454",
    name: "Infertility Treatment",
    icon: "/img/sample/infertility-icon.svg",
  },
];

const Services = ({data}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [hoverIndex, setHoverIndex] = useState();
  return (
    <div className={classes.servicesContainer}>
      <div className={classes.headSection}>
        <h3 className={classes.ourServices}>{data?.subTitle}</h3>
        {data?.showTitle && (
        <h3 className={classes.heading} dangerouslySetInnerHTML={{ __html: data?.title }}>
        </h3>
        )}
        <div className={classes.divideLine}></div>
        <h5 className={classes.description}>
          {data?.description}
        </h5>
      </div>
      <div className={classes.listSection}>
        {data?.listingItems?.map((service, index) => (
          <div
            key={`${index}${service._id}`}
            className={classes.serviceCard}
            onMouseEnter={() => {
              setIsHovered(true);
              setHoverIndex(index);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
              setHoverIndex(null);
            }}
            onTouchStart={() => {
              setIsHovered(false);
              setHoverIndex(null);
            }}
          >
            {isHovered && hoverIndex == index && (
              <img
                src="/img/icons/right-arrow.svg"
                alt=""
                className={classes.arrowIcon}
              />
            )}
            <img src={service?.icon} alt="" className={classes.serviceIcon} />
            <h4 className={classes.serviceName} title={service?.title}>
              {service?.title}
            </h4>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
