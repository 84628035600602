import React from "react";
import classes from "./ServicesBanner.module.css";

const ServicesBanner = () => {
  return (
    <div className={classes.outer}>
      <div className={classes.overLay}></div>
      <img src="/img/services-banner.png" className={classes.mainBanner} />
      <div className={classes.contentDiv}>
        <h1 className="bg-primary-200 primary-300">Home \ Services</h1>
        <h2>
          Comprehensive Healthcare Services at <span className="primary-500">Sabine Hospital</span>
        </h2>
        <p>
          Explore our wide range of specialized services tailored to provide
          exceptional care at every stage of life.
        </p>
      </div>
      <img src="/img/service-mother-baby.svg" className={classes.motherBaby} />
      <img className={classes.serviceWave} src="/img/service-wave.svg" />
    </div>
  );
};

export default ServicesBanner;
