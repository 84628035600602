import { Drawer, Dropdown, Menu } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import useDeviceWidth from "../../device-width/UseDeviceWidth";
import classes from "./Navbar.module.css";
import branchesService from "../../../services/branches";
import servicesService from "../../../services/services";
import NavbarDropdown from "./dropdown/NavbarDropdown";
import OurHospitals from "./dropdown/Services";
import Services from "./dropdown/Services";
import Hospitals from "./dropdown/Hospitals";

const Navbar = ({
  settings,
  branches,
  branchesLoading,
  services,
  servicesLoading,
}) => {
  const servicesButtonRef = useRef(null);
  const hospitalsButtonRef = useRef(null);
  const width = useDeviceWidth();
  const [sideMenuVisible, setSideMenuVisible] = useState(false);

  const [showServicesDropdown, setShowServicesDropdown] = useState(false);
  const [showHospitalsDropdown, setShowHospitalsDropdown] = useState(false);

  const openDrawer = () => {
    setSideMenuVisible(true);
  };

  const onClose = () => {
    setSideMenuVisible(false);
  };

  function isSameURL(givenURL) {
    const currentURL = window.location.pathname; // Get the current URL of the page
    return givenURL === currentURL; // Compare the given URL with the current URL
  }

  const menuItems = [
    {
      key: "our-hospitals",
      label: "Our Hospitals",
      children: branches?.map((branch) => {
        return {
          key: branch._id,
          label: <Link to={`/branches/${branch.slug}`}>{branch.name}</Link>,
        };
      }),
      // children: [
      //   {
      //     key: "our-hospitals-1",
      //     label: "Muvattupuzha",
      //   },
      //   {
      //     key: "our-hospitals-2",
      //     label: "Trivandrum",
      //   },
      // ],
    },
    {
      key: "services",
      label: "Services",
      children: services?.map((service) => {
        return {
          key: service._id,
          label: <Link to={`/services/${service.slug}`}>{service.title}</Link>,
        };
      }),
    },
  ];

  const closeServiceDropdown = (event) => {
    // Prevent closing when clicking the button
    if (
      servicesButtonRef.current &&
      servicesButtonRef.current.contains(event.target)
    ) {
      return;
    }
    setShowServicesDropdown(false);
  };

  const closeHospitalsDropdown = (event) => {
    // Prevent closing when clicking the button
    if (
      hospitalsButtonRef.current &&
      hospitalsButtonRef.current.contains(event.target)
    ) {
      return;
    }
    setShowHospitalsDropdown(false);
  };

  return (
    <>
      <header className={`${classes.navbarDiv}`}>
        <div className={`${classes.container}`}>
          {/* Logo section */}
          <Link to="/">
            <img src="/img/logo.svg" alt="logo" className={classes.logo} />
          </Link>

          {/* Navbar links and menu */}
          {width >= 1200 && (
            <nav>
              <ul className={classes.linksDiv}>
                <Link to="/">
                  <li className={classes.link}>
                    <div>Home</div>
                  </li>
                </Link>
                <Link to="/ivf">
                  <li className={classes.link}>
                    <div>IVF</div>
                  </li>
                </Link>
                <Link to="">
                  <li className={classes.link}>
                    {/* <Dropdown
                      menu={{
                        items: branches?.map((branch) => {
                          return {
                            key: branch._id,
                            label: (
                              <Link to={`/branch/${branch.slug}`}>
                                {branch.name}
                              </Link>
                            ),
                          };
                        }),
                      }}
                      // menu={menu}
                      placement="bottom"
                      trigger={["hover"]}
                      overlayClassName="navbar-menu"
                      dropdownRender={(menu) => <div className="">{menu}</div>}
                    > */}
                    <div
                      className={`${classes.dropLink}`}
                      // className="tw-text-primary-500 tw-font-primary tw-text-[16px] tw-font-semibold tw-leading-normal tw-not-italic tw-flex tw-flex-row tw-items-center tw-gap-[10px]"
                      ref={hospitalsButtonRef}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowHospitalsDropdown((prev) => !prev);
                      }}
                      // onMouseEnter={() => {
                      //   setShowDropdown(true);
                      // }}
                      // onMouseLeave={() => {
                      //   setShowDropdown(false);
                      // }}
                    >
                      Our Hospitals
                      <img
                        src="/img/icons/dropdown-arrow.svg"
                        alt=""
                        // className="tw-max-w-[124px] md:tw-max-w-[212px] lg:tw-max-w-[290px]"
                      />
                    </div>
                    {/* </Dropdown> */}
                  </li>
                </Link>
                <Link to="/">
                  <li className={classes.link}>
                    {/* <Dropdown
                      menu={{
                        items: services?.map((service) => {
                          return {
                            key: service._id,
                            label: (
                              <Link to={`/service/${service.slug}`}>
                                {service.title}
                              </Link>
                            ),
                          };
                        }),
                      }}
                      // menu={menu}
                      placement="bottom"
                      trigger={["hover"]}
                      overlayClassName="navbar-menu"
                      dropdownRender={(menu) => <div className="">{menu}</div>}
                    > */}
                    <div
                      className={`${classes.dropLink}`}
                      ref={servicesButtonRef}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowServicesDropdown((prev) => !prev);
                      }}
                    >
                      Services
                      <img
                        src="/img/icons/dropdown-arrow.svg"
                        alt=""
                        // className="tw-max-w-[124px] md:tw-max-w-[212px] lg:tw-max-w-[290px]"
                      />
                    </div>
                    {/* </Dropdown> */}
                  </li>
                </Link>
                <Link to="/doctors">
                  <li className={classes.link}>
                    <div>Doctors</div>
                  </li>
                </Link>
                <Link to="/contact">
                  <li className={classes.link}>
                    <div>Contact</div>
                  </li>
                </Link>
              </ul>
            </nav>
          )}

          {/* Buttons section */}
          <div className={classes.rightSection}>
            {width >= 768 && (
              <div className={classes.phoneDiv}>
                <img src="/img/icons/call-icon.svg" alt="logo" />
                <h4>0485-2838100</h4>
              </div>
            )}
            {width >= 768 && (
              <img src="/img/icons/nav-whatsapp.svg" alt="logo" />
            )}
            <button className={`${classes.bookOnline} primary-hover`}>
              Book Online
            </button>
            <img
              src="/img/icons/humburger.svg"
              alt=""
              className={classes.humburger}
              onClick={() => openDrawer()}
            />
          </div>
        </div>
        {showServicesDropdown && (
          <NavbarDropdown
            showDropdown={showServicesDropdown}
            closeDropdown={closeServiceDropdown}
          >
            <Services
              services={services}
              servicesLoading={servicesLoading}
              setShowServicesDropdown={setShowServicesDropdown}
            />
          </NavbarDropdown>
        )}
        {showHospitalsDropdown && (
          <NavbarDropdown
            showDropdown={showHospitalsDropdown}
            closeDropdown={closeHospitalsDropdown}
          >
            <Hospitals
              branches={branches}
              branchesLoading={branchesLoading}
              setShowHospitalsDropdown={setShowHospitalsDropdown}
            />
          </NavbarDropdown>
        )}
      </header>
      <Drawer
        title="Basic Drawer"
        placement="right"
        onClose={onClose}
        open={sideMenuVisible}
        key="right"
        width={width <= 768 ? 250 : width <= 1200 ? 400 : 434}
        className={`nav-menu ${classes.drawer}`}
        styles={{ header: { display: "none" } }}
      >
        <div className={`${classes.closeIconDiv}`} onClick={() => onClose()}>
          <img
            src="/img/icons/nav-close.svg"
            alt=""
            className={classes.closeIcon}
          />
        </div>
        <div className={classes.menuListDiv}>
          <Link to="/">
            <img src="/img/logo.svg" alt="" className={classes.menuLogo} />
          </Link>

          <div>
            <button className={`${classes.bookOnline} primary-hover`}>
              Book Online
            </button>
          </div>

          <div className={classes.quickContact}>
            <p className={classes.quickContactHead}> Quick Contact</p>
            <div className={classes.quickContactItemDiv}>
              <img src="/img/icons/side-phone.svg" alt="" />
              <a
                href={`tel:${settings?.contactNumber}`}
                className={classes.quickContactItem}
              >
                {settings?.contactNumber}
              </a>
            </div>
            <div className={classes.quickContactItemDiv}>
              <img src="/img/icons/side-whatsapp.svg" alt="" />
              <a
                href={`https://wa.me/${settings?.contactNumber}`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.quickContactItem}
              >
                {settings?.contactNumber}
              </a>
            </div>
          </div>

          <div className={classes.menuItems}>
            <Link to="/">
              <li
                className={`${classes.menuItem} ${
                  isSameURL("/") ? classes.bgPrimary : classes.bgWhite
                }`}
              >
                <div
                  className={`${
                    isSameURL("/") ? classes.textWhite : classes.textPrimary
                  }  ${classes.menuText}`}
                >
                  Home
                </div>
              </li>
            </Link>

            <Menu mode="inline" items={menuItems} className="filter-menu" />

            <Link to="/doctors">
              <li
                className={`${classes.menuItem} ${
                  isSameURL("/doctors") ? classes.bgPrimary : classes.bgWhite
                }`}
              >
                <div
                  className={`${
                    isSameURL("/doctors")
                      ? classes.textWhite
                      : classes.textPrimary
                  }  ${classes.menuText}`}
                >
                  Doctors
                </div>
              </li>
            </Link>
            <Link to="/contact">
              <li
                className={`${classes.menuItem} ${
                  isSameURL("/contact") ? classes.bgPrimary : classes.bgWhite
                }`}
              >
                <div
                  className={`${
                    isSameURL("/contact")
                      ? classes.textWhite
                      : classes.textPrimary
                  }  ${classes.menuText}`}
                >
                  Contact
                </div>
              </li>
            </Link>
            <Link to="/medical-tourism">
              <li
                className={`${classes.menuItem} ${
                  isSameURL("/medical-tourism")
                    ? classes.bgPrimary
                    : classes.bgWhite
                }`}
              >
                <div
                  className={`${
                    isSameURL("/medical-tourism")
                      ? classes.textWhite
                      : classes.textPrimary
                  }  ${classes.menuText}`}
                >
                  Medical Tourism
                </div>
              </li>
            </Link>
            <Link to="/testimonials">
              <li
                className={`${classes.menuItem} ${
                  isSameURL("/testimonials")
                    ? classes.bgPrimary
                    : classes.bgWhite
                }`}
              >
                <div
                  className={`${
                    isSameURL("/testimonials")
                      ? classes.textWhite
                      : classes.textPrimary
                  }  ${classes.menuText}`}
                >
                  Testimonials
                </div>
              </li>
            </Link>
            <Link to="/about-us">
              <li
                className={`${classes.menuItem} ${
                  isSameURL("/about-us") ? classes.bgPrimary : classes.bgWhite
                }`}
              >
                <div
                  className={`${
                    isSameURL("/about-us")
                      ? classes.textWhite
                      : classes.textPrimary
                  }  ${classes.menuText}`}
                >
                  About Us
                </div>
              </li>
            </Link>
            <Link to="/our-story">
              <li
                className={`${classes.menuItem} ${
                  isSameURL("/our-story") ? classes.bgPrimary : classes.bgWhite
                }`}
              >
                <div
                  className={`${
                    isSameURL("/our-story")
                      ? classes.textWhite
                      : classes.textPrimary
                  }  ${classes.menuText}`}
                >
                  Our Story
                </div>
              </li>
            </Link>
            <Link to="/management-team">
              <li
                className={`${classes.menuItem} ${
                  isSameURL("/management-team")
                    ? classes.bgPrimary
                    : classes.bgWhite
                }`}
              >
                <div
                  className={`${
                    isSameURL("/management-team")
                      ? classes.textWhite
                      : classes.textPrimary
                  }  ${classes.menuText}`}
                >
                  Management Team
                </div>
              </li>
            </Link>
            <Link to="/gallery">
              <li
                className={`${classes.menuItem} ${
                  isSameURL("/gallery") ? classes.bgPrimary : classes.bgWhite
                }`}
              >
                <div
                  className={`${
                    isSameURL("/gallery")
                      ? classes.textWhite
                      : classes.textPrimary
                  }  ${classes.menuText}`}
                >
                  Gallery
                </div>
              </li>
            </Link>
            <Link to="/careers">
              <li
                className={`${classes.menuItem} ${
                  isSameURL("/careers") ? classes.bgPrimary : classes.bgWhite
                }`}
              >
                <div
                  className={`${
                    isSameURL("/careers")
                      ? classes.textWhite
                      : classes.textPrimary
                  }  ${classes.menuText}`}
                >
                  Careers
                </div>
              </li>
            </Link>
            <Link to="/csr-policy">
              <li
                className={`${classes.menuItem} ${
                  isSameURL("/csr-policy") ? classes.bgPrimary : classes.bgWhite
                }`}
              >
                <div
                  className={`${
                    isSameURL("/csr-policy")
                      ? classes.textWhite
                      : classes.textPrimary
                  }  ${classes.menuText}`}
                >
                  CSR Policy
                </div>
              </li>
            </Link>
            <Link to="/refund-policy">
              <li
                className={`${classes.menuItem} ${
                  isSameURL("/refund-policy")
                    ? classes.bgPrimary
                    : classes.bgWhite
                }`}
              >
                <div
                  className={`${
                    isSameURL("/refund-policy")
                      ? classes.textWhite
                      : classes.textPrimary
                  }  ${classes.menuText}`}
                >
                  Refund and Return Policy
                </div>
              </li>
            </Link>
            <Link to="/terms-of-use">
              <li
                className={`${classes.menuItem} ${
                  isSameURL("/terms-of-use")
                    ? classes.bgPrimary
                    : classes.bgWhite
                }`}
              >
                <div
                  className={`${
                    isSameURL("/terms-of-use")
                      ? classes.textWhite
                      : classes.textPrimary
                  }  ${classes.menuText}`}
                >
                  Terms of use
                </div>
              </li>
            </Link>
            <Link to="/privacy-policy">
              <li
                className={`${classes.menuItem} ${
                  isSameURL("/privacy-policy")
                    ? classes.bgPrimary
                    : classes.bgWhite
                }`}
              >
                <div
                  className={`${
                    isSameURL("/privacy-policy")
                      ? classes.textWhite
                      : classes.textPrimary
                  }  ${classes.menuText}`}
                >
                  Privacy Policy
                </div>
              </li>
            </Link>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Navbar;
