import React, { useRef } from "react";
import classes from "./TestimonialsList.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import useDeviceWidth from "../../../components/device-width/UseDeviceWidth";
import TestimonialCard from "./testimonial-card/TestimonialCard";

const TestimonialsList = ({ data, backgroundClass = "#FFF", isShadow }) => {
  const swiperRef = useRef(null);
  const width = useDeviceWidth();
  console.log('testimonial data', data)

  return (
    <div className={`${classes.outer} ${backgroundClass} ${isShadow ? classes.removePadding : ''}`}>
      <div className={classes.inner}>
        <img
          src="/img/testimonial-pattern.svg"
          className={classes.pattern}
        ></img>
        <div className={classes.innerContent}>
          <div className={classes.title}>
            <h1 className="bg-primary-200 primary-300">{data?.subTitle}</h1>
            <div>
              {data?.showTitle && (
              <div className={classes.titleFirst}>
                <h2 dangerouslySetInnerHTML={{ __html: data?.title }}></h2>
              </div>
              )}
            </div>
            <div className={classes.underLine}></div>
            <button
              className={`${classes.read} border-primary-500 primary-hover`}
            >
              Read All Testimonial
            </button>
          </div>
          <div className={classes.sliderOuter}>
            <div className={classes.arrowDiv}>
              <img
                src="/img/title-symbol.svg"
                className={classes.titleSymbol}
              ></img>
              <div className={classes.arrows}>
                <img
                  className={classes.leftArrow}
                  src="/img/doctor-left.svg"
                  alt="Prev"
                  onClick={() => swiperRef.current?.slidePrev()} // Trigger previous slide
                />
                <img
                  src="/img/doctor-right.svg"
                  className={classes.rightArrow}
                  alt="Next"
                  onClick={() => swiperRef.current?.slideNext()} // Trigger next slide
                />
              </div>
            </div>
            <div className={`${isShadow ? `testimonial-list` : ''}`}>
              <div>
                {/* Custom Navigation Arrows */}

                {/* Swiper */}
                <Swiper
                  //   modules={[Navigation, Pagination]}
                  slidesPerView={width > 1100 ? 2 : 1} // Show 3 images at a time
                  spaceBetween={40} // Add space between slides
                  onSwiper={(swiper) => (swiperRef.current = swiper)} // Attach the Swiper instance to the ref
                  pagination={{ clickable: true }} // Enable clickable dots
                  loop={true} // Enable infinite looping
                  draggable={true} // Enable drag/swipe functionality
                  modules={[Autoplay]}
                  autoplay={{
                    delay: 3000, // 3 seconds
                    disableOnInteraction: false, // Autoplay continues even after interaction
                  }}
                >
                  {data?.listingItems?.map((slide) => (
                    <SwiperSlide key={slide.id} >
                      <TestimonialCard isShadow = {isShadow} data = {slide} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsList;
