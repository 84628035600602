import React from "react";
import classes from "./ListingGrid.module.css";
import ListingCard from "./ListingCard";

const ListingGrid = ({services}) => {
  return (
    <div className={classes.outer}>
      <div className={classes.title}>
        <h1 className="bg-primary-200 primary-300">Our Services</h1>
        <h2>Experience the Best in Healthcare</h2>
        <img src="/img/title-symbol.svg" />
        <p>
          Delivering Compassionate Care with Expertise in Pediatrics,
          Infertility, and General Medicine
        </p>
      </div>
      <div className={classes.listingGrid}>
        {services?.map((data)=>(
            <ListingCard data={data} />
        ))}
      </div>
    </div>
  );
};

export default ListingGrid;
