import React, { useEffect, useRef, useState } from "react";
import classes from "./BranchBanner.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import useDeviceWidth from "../../../components/device-width/UseDeviceWidth";
import { Select } from "antd";
import HospitalIcon from "../../../components/UI/HospitalIcon";
import branchesService from "../../../services/branches";
import departmentsService from "../../../services/departments";
import doctorsService from "../../../services/doctors";

const { Option } = Select;

const BranchBanner = ({ data, branchData }) => {
  const width = useDeviceWidth();
  const swiperRef = useRef(null); // Create a ref to control the Swiper instance
  console.log('branch banner data', data, branchData)
  const [doctors, setDoctors] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState();
    const [selectedDepartment, setSelectedDepartment] = useState();
    const [selectedDoctor, setSelectedDoctor] = useState();

    useEffect(()=>{
        setSelectedBranch(branchData?._id)
    },[branchData])
  
  
    const getDepartments = async (query) => {
      const response = await departmentsService.getAll(query);
      console.log("departments data", response);
      setDepartments(response?.data);
    };
  
    const getDoctors = async (query) => {
      const response = await doctorsService.getAll(query);
      console.log("doctors data", response);
      setDoctors(response?.data);
    };
  
  
    useEffect(() => {
      setSelectedDepartment();
      if (selectedBranch) {
        getDepartments(`?branchId=${selectedBranch}`);
      }
    }, [selectedBranch]);
  
    useEffect(() => {
      if (selectedDepartment || selectedBranch) {
        setSelectedDoctor()
        let query = "";
  
        if (selectedDepartment) {
          query += `?departmentId=${selectedDepartment}`;
        }
  
        if (selectedBranch) {
          query += query
            ? `&branchId=${selectedBranch}`
            : `?branchId=${selectedBranch}`;
        }
  
        getDoctors(query);
      }
    }, [selectedDepartment, selectedBranch]);

  const infoDiv = (data) => {
    const element = (
      <>
        <h5 className={classes.bannerTag}>{branchData?.name}</h5>
        <h4 className={classes.bannerHeading}>
          {data?.title}
        </h4>
        <h4 className={classes.bannerPara}>
          {data?.description}
        </h4>
        <div className={classes.buttonDiv}>
          <button className={`${classes.bookOnline} primary-hover`}>
            Book Your Appointment Today
          </button>
          <button className={`${classes.exploreButton} primary-hover`}>
            Explore Our Services
          </button>
        </div>
      </>
    );

    return (
      <>
        <div className={classes.infoDiv}>
          <div className={classes.divBackground}>{element}</div>
          {/* {element} */}
        </div>
        <div className={classes.infoDiv}>
          {" "}
          <div className={classes.contentHolder}>{element}</div>
        </div>
      </>
    );
  };

  const searchInputs = () => {
    return (
      <>
        <Select
          showSearch
          placeholder="Select Expertise"
          onChange={(value)=>setSelectedDepartment(value)}
          value={selectedDepartment}
          className="search-select"
          prefix={
            <img
              src="/img/icons/heart-icon.svg"
              alt="Icon"
              style={{ marginTop: "5px", marginRight: "3px" }}
            />
          }
          suffixIcon={
            <img
              src="/img/icons/dropdown-arrow.svg"
              alt="Icon"
              style={{ width: 14, height: 14, marginRight: "10px" }}
            />
          }
        >
          {departments?.map((item)=>(
          <Option value={item?._id}>{item?.name}</Option>
        ))}
        </Select>
        <Select
          showSearch
          onChange={(value)=>setSelectedDoctor(value)}
          value={selectedDoctor}
          placeholder="Select Doctor"
          className="search-select"
          prefix={
            <img
              src="/img/icons/doc-icon.svg"
              alt="Icon"
              style={{ marginTop: "5px", marginRight: "3px" }}
            />
          }
          suffixIcon={
            <img
              src="/img/icons/dropdown-arrow.svg"
              alt="Icon"
              style={{ width: 14, height: 14, marginRight: "10px" }}
            />
          }
        >
         {doctors?.map((item)=>(
          <Option value={item?._id}>{item?.name}</Option>
        ))}
        </Select>
        <button className={`${classes.searchButton} primary-hover`}>
          {" "}
          <img
            src="/img/icons/search.svg"
            alt=""
            //   style={{ marginTop: "5px", marginRight: "3px" }}
          />
          Search
        </button>
      </>
    );
  };

  return (
    <div className={`outer-outer ${classes.outerContainer}`}>
      {/* <h1>Title for this website</h1> */}
      <div style={{ position: "relative", width: "100%", margin: "auto" }}>
        {/* <img
          src="/img/designs/banner-top-dots.svg"
          alt=""
          className={classes.sliderDesign}
        /> */}

        {/* {infoDiv()} */}

        {width > 1200 && (
          <div className={classes.searchDiv}>{searchInputs()}</div>
        )}

        {/* Swiper */}
        <Swiper
          className="banner-swiper"
          modules={[Navigation, Pagination, Autoplay]}
          slidesPerView={1} // Show 3 images at a time
          spaceBetween={20} // Add space between slides
          onSwiper={(swiper) => (swiperRef.current = swiper)} // Attach the Swiper instance to the ref
          pagination={{ clickable: true }} // Enable clickable dots
          loop={true} // Enable infinite looping
          style={{ marginBottom: "-4px" }} // Add space for pagination dots
          draggable={true} // Enable drag/swipe functionality
          autoplay={{
            delay: 4000, // Delay between transitions (in milliseconds)
            disableOnInteraction: false, // Keep autoplay running even after user interaction
          }}
        >
          {data?.listingItems?.map((slide) => (
            <SwiperSlide key={slide.id}>
              {infoDiv(slide)}
              <img
                src={width > 1200 ? slide.webImage : slide.mobileImage}
                // src={image}
                // alt={Slide ${slide.id}}
                alt=""
                className={classes.sliderImage}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {width < 1200 && (
        <div className={classes.searchContainer}>{searchInputs()}</div>
      )}
    </div>
  );
};

export default BranchBanner;
