import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from './AppRoutes';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { Layout } from './components/layout/Layout';
import { ConfigProvider } from 'antd';
import { useEffect } from 'react';
import { widgetActions } from './redux store/widgetSlice';
import { useDispatch } from 'react-redux';

function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch(`${API_BASE_URL}home/generate-file`);

        // const data = await response.json();
        // const fileUrl = data.fileUrl;

        const fileUrl =
          "https://sabine-test.s3.ap-south-1.amazonaws.com/home-file.txt";

        // Fetch the text file
        const textResponse = await fetch(fileUrl, {
          method: "GET",
          headers: {
            "Cache-Control": "no-cache",
          },
          cache: "no-cache",
        });
        console.log("text response is", textResponse);

        const textData = await textResponse.text();
        console.log("text data is", textData);
        const parsedData = JSON.parse(textData);
        console.log("parsed data", parsedData);
        const widgets = parsedData?.widgets;
        console.log("widgets in app.js", widgets);
        dispatch(widgetActions.setWidgets({ widgets: parsedData?.widgets }));

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <ErrorBoundary>
      <Router>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: 'Montserrat, sans-serif', // Change to your desired font family
            },
          }}
        >
          <Layout>
            <AppRoutes />
          </Layout>
        </ConfigProvider>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
