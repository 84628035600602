import React, { useEffect, useRef } from "react";
import classes from "./NavbarDropdown.module.css";

const NavbarDropdown = ({ children, showDropdown, closeDropdown }) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the dropdown container
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown(event); // Close the dropdown
      }
    };

    // Attach the event listener to the document
    window.addEventListener("pointerdown", handleClickOutside);

    return () => {
      // Cleanup the event listener
      window.removeEventListener("pointerdown", handleClickOutside);
    };
  }, [closeDropdown]);

  return (
    <div
      ref={dropdownRef}
      className={`${classes.dropdownContainer} ${
        showDropdown ? classes.open : classes.close
      }`}
    >
      {children}
    </div>
  );
};

export default NavbarDropdown;
