import React from "react";
import classes from "./DoctorsList.module.css";
import DoctorCard from "../../home-page/doctors-list/doctor-card/DoctorCard";
import useDeviceWidth from "../../../components/device-width/UseDeviceWidth";

const doctorArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const DoctorsList = ({data}) => {
  const width = useDeviceWidth();
  const sliceAmount = width > 1600 ? 10 : width > 1024 ? 8 : 2;

  return (
    <div className={classes.outer}>
      <div className={`${classes.title}`}>
        <h1 className="primary-300 bg-primary-200">{data?.subTitle}</h1>
        {data?.showTitle && (
        <h2 dangerouslySetInnerHTML={{ __html: data?.title }}>
        </h2>
        )}
      </div>
      <div className={classes.list}>
        {data?.listingItems?.slice(0, sliceAmount)?.map((item) => (
          <DoctorCard data = {item} />
        ))}
      </div>
      <div className={classes.buttonDiv}>
        <button
          className={`${classes.showAllDoctors} border-primary-500 primary-hover`}
        >
          Show All Doctors
        </button>
      </div>
    </div>
  );
};

export default DoctorsList;
