import React, { useEffect, useState } from 'react'
import classes from './ServicesListing.module.css'
import ServicesBanner from './banner/ServicesBanner'
import ListingGrid from './listing-grid/ListingGrid'
import AdvancedFacilitiesList from '../branch-page/advanced-facilities/AdvancedFacilitiesList'
import servicesService from '../../services/services'
import LoadingSpinner from '../../components/loader/LoadingSpinner'


const ServicesListing = () => {

    const [loading, setLoading] = useState(true);
    const [services, setServices] = useState([]);
    

    const getServices = async () => {
        setLoading(true);
        try {
          const data = await servicesService.getAll()
          if (data?.status == 404) {
            setServices([]);
            setLoading(false);
            return;
          }
          console.log("fetched data", data);
          if (data?.data) {
            setServices(data?.data)
            setLoading(false);
          } else {
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
        }
      };

      useEffect(()=>{
        getServices()
      },[])

      return (
        <>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <ServicesBanner />
              <ListingGrid services={services} />
              <AdvancedFacilitiesList />
            </>
          )}
        </>
      );
      
}

export default ServicesListing
