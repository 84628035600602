import React from 'react'
import classes from './AdvancedCard.module.css'

const AdvancedCard = () => {

    const timings = [
        {time: '09:30 AM - 01:30 PM',slots: '50 Slots'},
        {time: '01:30 AM - 51:30 PM',slots: '50 Slots'},
    ]

  return (
    <div className={classes.outer}>
       <img src="/img/advanced-sample.svg" />
       <p>Neonatal Intensive Care Unit (NICU)</p>
    </div>
  )
}

export default AdvancedCard
