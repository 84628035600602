import React from 'react'

const NavbarSliderRightIcon = ({ className, onClick }) => {
    return (
        <svg className={className} onClick={onClick} width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1.3335" width="34" height="34" rx="17" fill="#542F68" fill-opacity="0.1" />
            <rect x="1" y="1.3335" width="34" height="34" rx="17" stroke="white" stroke-width="2" />
            <path d="M14.5 25.3335L21.5 18.3335L14.5 11.3335" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default NavbarSliderRightIcon