import React from 'react'
import classes from './DoctorCard.module.css'

const DoctorCard = ({data}) => {

    const timings = [
        {time: '09:30 AM - 01:30 PM',slots: '50 Slots'},
        {time: '01:30 AM - 51:30 PM',slots: '50 Slots'},
    ]

  return (
    <div className={classes.outer}>
        <img className={classes.doctorImage} src={data?.image?.url} />
        <div className={classes.title}>
            <h1>{data?.name}</h1>
            <h2>{data?.departmentName}</h2>
            <div className={classes.qualifications}>
                {data?.qualification?.map((item)=>(
                    <p className="bg-primary-200 primary-300">{item}</p>
                ))}
            </div>
            <div className={classes.timings}>
                {[data?.timings]?.map((time)=>(
                    <div className={classes.timeDiv}>
                        <img className={classes.checkBox} src="/img/checkbox.svg" />
                        <div className={classes.slots}>
                            <div className={classes.timeList}>
                                <img src="/img/clock-icon.svg" />
                                <p className="primary-300">{time}</p>
                            </div>
                            <h4>Test Slots</h4>
                        </div>
                    </div>
                ))}
            </div>
        </div>
      <button className="primary-hover">Book Appointment</button>
    </div>
  )
}

export default DoctorCard
