import React from "react";
import classes from "./TestimonialCard.module.css";

const TestimonialCard = ({isShadow, data}) => {
  const timings = [
    { time: "09:30 AM - 01:30 PM", slots: "50 Slots" },
    { time: "01:30 AM - 51:30 PM", slots: "50 Slots" },
  ];

  return (
    <div
      className={`${classes.outer} ${isShadow ? classes.showShadow : ''} ${isShadow ? "swiper-padding" : ''}`}
    >
      <div className={classes.titleDiv}>
        <div className={classes.title}>
          <img
            className={classes.profileImage}
            src={data?.profileImage ? data?.profileImage : "/img/testimonial-profile.svg"}
          />
          <div>
            <h1>{data?.name}</h1>
            <h2>{data?.location}</h2>
          </div>
        </div>
        <img className={classes.testIcon} src="/img/testimonial-icon.svg" />
      </div>
      <p className={classes.content}>
        {data?.content}
      </p>
    </div>
  );
};

export default TestimonialCard;
