import React, { useEffect, useState } from "react";
import AdvancedFacilitiesList from "./advanced-facilities/AdvancedFacilitiesList";
import ExperienceExcellence from "./experience-excellence/ExperienceExcellence";
import TestimonialsList from "../home-page/testimonials-list/TestimonialsList";
import BranchBanner from "./banner/BranchBanner";
import Services from "./services/Services";
import DoctorsList from "./doctors-list/DoctorsList";
import { useDispatch, useSelector } from "react-redux";
import useDeviceWidth from "../../components/device-width/UseDeviceWidth";
import { useParams } from "react-router-dom";
import branchesService from "../../services/branches";

const BranchHome = () => {
  const { widgets } = useSelector((state) => state.widget); // Access widget data
  const [widgetData, setWidgetData] = useState([]);
  const [branchData, setBranchData] = useState();
  console.log("widgets in branch are", widgetData);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const width = useDeviceWidth();
  const { slug } = useParams();

  useEffect(() => {

    const getBranch = async () => {
      const data = await branchesService.getBySlug(slug);
      console.log('data fetched', data)
      setBranchData(data?.data);
    };

    getBranch();

  }, [slug]);

  console.log('single branch', branchData)

  useEffect(() => {
    if (widgets?.widgets) {
      const data = widgets?.widgets?.filter(
        (item) =>
          item?.WidgetScope == "Branch Specific" &&
          item?.branches?.find((branch) => branch?.slug == slug)
      );
      setWidgetData(data);
    }
  }, [widgets]);

  return (
    <div>
      {widgetData?.map((item) => {
        // Determine if the component should be shown based on device type
        const shouldShowOnDesktop = item?.deviceType?.includes("Desktop");
        const shouldShowOnMobile = item?.deviceType?.includes("Mobile");

        // Render based on the device type and the current device
        if (shouldShowOnDesktop && width > 600) {
          if (item.type === "Banner") {
            return <BranchBanner data={item} branchData = {branchData} />;
          } else if (item.type === "Page" && item?.designType == "FACILITIES") {
            return <AdvancedFacilitiesList data={item} />;
          } else if (item.type === "Page" && item?.designType == "ABOUT US") {
            return <ExperienceExcellence data={item} />;
          } else if (item.type === "Services") {
            return <Services data={item} />;
          } else if (item.type === "Doctors") {
            return <DoctorsList data={item} />;
          } else if (
            item.type === "Testimonial" &&
            item?.testimonialType == "CONTENT"
          ) {
            return <TestimonialsList data={item} isShadow={true} />;
          } else if (item.type === "Static") {
            return (
              <div
                className="home-static"
                dangerouslySetInnerHTML={{ __html: item?.staticContent }}
              ></div>
            );
          } else {
            // return <div key={item._id}>Unknown Type</div>;
          }
        } else if (shouldShowOnMobile && width <= 768) {
          // Similar conditional rendering for mobile
          if (item.type === "Banner") {
            return <BranchBanner data={item} branchData = {branchData} />;
          } else if (item.type === "Page" && item?.designType == "FACILITIES") {
            return <AdvancedFacilitiesList data={item} />;
          } else if (item.type === "Page" && item?.designType == "ABOUT US") {
            return <ExperienceExcellence data={item} />;
          } else if (item.type === "Services") {
            return <Services data={item} />;
          } else if (item.type === "Doctors") {
            return <DoctorsList data={item} />;
          } else if (
            item.type === "Testimonial" &&
            item?.testimonialType == "CONTENT"
          ) {
            return <TestimonialsList data={item} isShadow={true} />;
          } else if (item.type === "Static") {
            return (
              <div
                className="home-static"
                dangerouslySetInnerHTML={{ __html: item?.staticContent }}
              ></div>
            );
          } else {
            // return <div key={item._id}>Unknown Type</div>;
          }
        } else {
          return null; // Do not render anything if the device type does not match
        }
      })}
      {/* <BranchBanner />
      <Services />
      <DoctorsList />
      <AdvancedFacilitiesList />
      <ExperienceExcellence />
      <TestimonialsList isShadow={true} /> */}
    </div>
  );
};

export default BranchHome;
