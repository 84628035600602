import React, { useState } from "react";
import classes from "./ExperienceExcellence.module.css";
import { Modal } from "antd";
import ReactPlayer from "react-player";
import ExperienceCarousel from "./ExperienceCarousel";

const ExperienceExcellence = () => {
  return (
    <>
      <div className={classes.outer}>
        <div className={classes.left}>
          <span className="bg-primary-200 primary-500">
            About the Tanur Branch
          </span>
          <div className={classes.title}>
            <h3>Experience Excellence in Healthcare at</h3>
            <h4 className="primary-500">Muvattupuzha</h4>
          </div>
          <div className={classes.underLine}></div>
          <p>
            Providing cutting-edge medical treatments with a commitment to
            compassionate, patient-centered care for the Tanur community. Sabine
            Hospital, Tanur is equipped with cutting-edge medical technology and
            offers a range of specialized treatments, including pediatrics,
            infertility care, and advanced surgeries. Our dedicated team of
            healthcare professionals ensures that patients receive personalized,
            compassionate care in a modern, patient-friendly environment.
          </p>
          <button className="primary-hover border-primary-500">Know More About Sabine Group</button>
        </div>
        <div className={classes.right}>
          <ExperienceCarousel />
        </div>
      </div>
    </>
  );
};

export default ExperienceExcellence;
