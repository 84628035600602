import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import classes from "./ExperienceExcellence.module.css";

function ExperienceCarousel() {
  const swiperRef = useRef(null); // Create a ref to control the Swiper instance

  const slides = [
    {
      id: 1,
      url: "https://img.freepik.com/premium-photo/modern-automobile-classic-technology-wheel-traffic_665346-119.jpg",
    },
    {
      id: 2,
      url: "https://img.freepik.com/premium-photo/modern-automobile-classic-technology-wheel-traffic_665346-119.jpg",
    },
    {
      id: 3,
      url: "https://img.freepik.com/premium-photo/modern-automobile-classic-technology-wheel-traffic_665346-119.jpg",
    },
  ];

  return (
    <div className="experience-carousel">
      <div style={{ position: "relative", width: "100%", margin: "auto" }}>
        {/* Swiper */}
        <Swiper
          modules={[Navigation, Pagination, Autoplay]} // Enable navigation and pagination
          autoplay={{
            delay: 3000, // 3 seconds
            disableOnInteraction: false, // Autoplay continues even after interaction
          }}
          slidesPerView={1} // Show 3 images at a time
          spaceBetween={20} // Add space between slides
          onSwiper={(swiper) => (swiperRef.current = swiper)} // Attach the Swiper instance to the ref
          pagination={{ clickable: true }} // Enable clickable dots
          loop={true} // Enable infinite looping
          draggable={true} // Enable drag/swipe functionality
          className="exp-carousel"
        >
          {slides.map((slide) => (
            <SwiperSlide key={slide.id}>
              <img
                src={slide.url}
                alt={`Slide ${slide.id}`}
                className={classes.experienceImage}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default ExperienceCarousel;
