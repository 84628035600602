import React, { useEffect, useRef, useState } from "react";
import classes from "./AdvancedFacilities.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import useDeviceWidth from "../../../components/device-width/UseDeviceWidth";
import { Autoplay, Pagination } from "swiper/modules";
import AdvancedCard from "./advanced-card/AdvancedCard";

export const textasa = "hi";

const AdvancedFacilitiesList = () => {
  const swiperRef = useRef(null);
  const width = useDeviceWidth();
  const [isAutoplayRunning, setIsAutoplayRunning] = useState(true);
  const divRef = useRef(null);
  const [widthValue, setWidthValue] = useState();

  useEffect(() => {
    const calculateWert = () => {
      const vw = window.innerWidth; // Get the width of the viewport in pixels
      const result = 0.91 * vw - 100; // Perform the calculation
      setWidthValue(result); // Update the state
    };

    // Initial calculation
    calculateWert();

    // Recalculate on window resize
    window.addEventListener("resize", calculateWert);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("resize", calculateWert);
    };
  }, []);

  const toggleAutoplay = () => {
    if (swiperRef.current) {
      if (isAutoplayRunning) {
        swiperRef.current.autoplay.stop(); // Stop autoplay
      } else {
        swiperRef.current.autoplay.start(); // Start autoplay
      }
      setIsAutoplayRunning(!isAutoplayRunning);
    }
  };

  console.log("width value is", widthValue);

  return (
    <div className={`${classes.outer}`}>
      <div className={`${classes.inner} bg-primary-500`}>
        <img src="/img/gallery-pattern.svg" className={classes.pattern}></img>
        <div className={classes.innerContent}>
          <div className={classes.title} ref={divRef}>
            <h1 className="primary-300 bg-primary-200">Facilities & Technology</h1>
            <div
              style={{
                width: width < 769 && "80%",
                textAlign: width < 769 && "center",
              }}
            >
              <h2>Advanced Facilities & Cutting-Edge Technology</h2>
            </div>
            <div className={`${classes.underLine} bg-secondary-200`}></div>
            <h4 className={classes.description}>
              At Sabine Hospital, Tanur, we pride ourselves on providing the
              highest standard of care through our state-of-the-art facilities
              and modern technology. Designed with patient comfort and safety in
              mind, our hospital is equipped with advanced medical technologies
              that enable faster, more accurate diagnostics and treatments.
              Explore our facilities and see how we make world-class healthcare
              accessible.
            </h4>
          </div>
          <div className={classes.sliderOuter}>
          <div className={classes.arrowDiv}>
            <div className={classes.arrows}>
              <img
                className={classes.leftArrow}
                src="/img/arrow-left-white.svg"
                alt="Prev"
                onClick={() => swiperRef.current?.slidePrev()} // Trigger previous slide
              />
              <img
                src="/img/arrow-right-white.svg"
                className={classes.rightArrow}
                alt="Next"
                onClick={() => swiperRef.current?.slideNext()} // Trigger next slide
              />
            </div>
          </div>
            <div className={`${classes.swiperOuter} outer-outer`}>
              <div>
                {/* Custom Navigation Arrows */}

                {/* Swiper */}
                <Swiper
                  //   modules={[Navigation, Pagination]}
                  slidesPerView={
                    width > 1700
                      ? 4
                      : width > 1200
                      ? 3
                      : width > 600
                      ? 2
                      : 1
                  } // Show 3 images at a time
                  modules={[Autoplay]}
                  // speed={5000}
                  autoplay={{
                    delay: 3000, // 3 seconds
                    disableOnInteraction: false, // Autoplay continues even after interaction
                  }}
                  spaceBetween={20} // Add space between slides
                  onSwiper={(swiper) => (swiperRef.current = swiper)} // Attach the Swiper instance to the ref
                  pagination={{ clickable: true }} // Enable clickable dots
                  loop={true} // Enable infinite looping
                  draggable={true} // Enable drag/swipe functionality
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(
                    (slide) => (
                      <SwiperSlide key={slide.id}>
                        <AdvancedCard />
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedFacilitiesList;
