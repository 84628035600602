import React from "react";
import classes from "./ListingCard.module.css";

const ListingCard = ({data}) => {
  return (
    <div className={classes.outer}>
      <div className={classes.overLay}></div>
      <img className={classes.serviceImage} src={data?.image?.url} />
      <div className={classes.content}>
        <div className={classes.iconDiv}>
        <img className={classes.icon} src={data?.icon} />
        </div>
        <p>{data?.title}</p>
        <h3 >
          {data?.shortDescription}
        </h3>
        <div className={classes.learn}>
            <h5>Learn More</h5>
            <img src="/img/right-white-arrow.svg" />
        </div>
      </div>
    </div>
  );
};

export default ListingCard;
