import { createSlice } from '@reduxjs/toolkit';

const widgetSlice = createSlice({
  name: 'widget',
  initialState: {
    widgets: [{name: 'widgets'},{name: 'test'}], // Stores widget data
  },
  reducers: {
    setWidgets: (state, action) => {
      state.widgets = action.payload; // Replace the current data with new data
    },
  },
});

// Export actions
export const widgetActions = widgetSlice.actions;


// Export reducer
export default widgetSlice.reducer;
