import React from "react";
import classes from "./Footer.module.css";
import { useNavigate } from "react-router-dom";

const Footer = ({ settings, services }) => {
  const navigate = useNavigate();
  return (
    <div className={classes.footerContainer}>
      <div className={classes.footerSection}>
        <img
          src="/img/designs/footerBac.svg"
          alt=""
          className={classes.footerBack}
        />
        <div className={`${classes.column}`}>
          <img
            src="/img/footer-logo.svg"
            alt="logo"
            className={classes.footerlogo}
          />
          <div className={classes.divideLine}></div>
          <p className={classes.description}>
            Sabine Hospital is committed to delivering exceptional fertility
            care with a team of skilled IVF specialists. Our facility provides
            advanced in vitro fertilization (IVF) treatments alongside expertise
            in reproductive medicine and cutting-edge fertility technologies.
          </p>
          <div className={classes.buttonDiv}>
            <button
              className={`${classes.bookOnline} primary-hover`}
              onClick={() => navigate(`/doctors`)}
            >
              Make an Appointment
            </button>
            <button className={`${classes.exploreButton} primary-hover`}>
              Lab Report
            </button>
          </div>
          <div className={classes.quickContactDiv}>
            <h4 className={classes.contactTitle}>Quick Contact</h4>
            <div className={classes.quickContactSection}>
              <div className={classes.quickContactItemDiv}>
                <img src="/img/icons/footer-phone.svg" alt="" />
                <a
                  href={`tel:${settings?.contactNumber}`}
                  className={classes.quickContactItem}
                >
                  {settings?.contactNumber}
                </a>
              </div>
              <div className={classes.quickContactItemDiv}>
                <img src="/img/icons/footer-whatsapp.svg" alt="" />
                <a
                  href={`https://wa.me/${settings?.contactNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.quickContactItem}
                >
                  {settings?.contactNumber}
                </a>
              </div>
            </div>
            <div className={classes.socialMediaDiv}>
              <a
                href={settings?.facebookLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/icons/footer-facebook.svg" alt="" />
              </a>
              <a
                href={settings?.instagramLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/icons/footer-instagram.svg" alt="" />
              </a>
              <a
                href={settings?.linkedInLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/icons/footer-linkedin.svg" alt="" />
              </a>
              <a
                href={settings?.twitterLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/icons/footer-x.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className={`${classes.column}`}>
          <h4 className={classes.columnHeading}>
            Medical Specialties and Services
          </h4>
          <ul className={classes.listing}>
            {services.length > 0 &&
              services?.map((service) => (
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service/${service.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {service.title}
                  </a>
                </li>
              ))}
          </ul>
        </div>
        <div className={`${classes.column}`}>
          <h4 className={classes.columnHeading}>Quick Links</h4>
          <ul className={classes.listing}>
            <div className={classes.quickLinkGrid}>
              <div className={classes.quickLinkGridCol}>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Medical Tourism
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Testimonials
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Gallery
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Services
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Careers
                  </a>
                </li>
              </div>
              <div className={classes.quickLinkGridCol}>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    What's new
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CSR Policy
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Refund and Return Policy
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of use
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </li>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div className={classes.versionSection}>
        <h4 className={classes.versionText}>
          Copyright sabinehospital.com © {new Date().getFullYear()}. v1.1.1 |
          All rights reserved | Developed By{" "}
          <a href="https://riolabz.com" target="_blank" rel="noreferrer">
            Riolabz
          </a>
        </h4>
      </div>
    </div>
  );
};

export default Footer;
