import fetch from "../components/Fetchinterceptor/FetchInterceptor";


const settingsService = {}
const apiRoute = '/adminSettings';

export const hellohidata = 'hi'

settingsService.getAll = async function (query = '') {
    try {
        const res = await fetch({
            url: `${apiRoute}/get/all/public${query}`,
            method: 'get'
        });
        return res;
    } catch (err) {
        console.log(err, 'show-err');
        return err
    }
}

export default settingsService;