import { configureStore } from '@reduxjs/toolkit';
import widgetReducer from './widgetSlice';

const store = configureStore({
  reducer: {
    widget: widgetReducer, // Add the widget reducer
  },
});

export default store;
