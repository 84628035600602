import React, { useEffect, useState } from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import classes from "./Layout.module.css";
import settingsService from "../../services/settings";
import branchesService from "../../services/branches";
import servicesService from "../../services/services";

export const Layout = ({ children }) => {
  const [branches, setBranches] = useState([]);
  const [branchesLoading, setBranchesLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [servicesLoading, setServicesLoading] = useState(false);
  const [settings, setSettings] = useState();
  console.log("settings in layout", settings);

  const getSettings = async () => {
    const data = await settingsService.getAll();

    if (data) {
      setSettings(data.data[0]);
    }
  };
  const getServices = async () => {
    setServicesLoading(true);
    const data = await servicesService.getAll();

    if (data) {
      setServices(data.data);
    }
    setServicesLoading(false);
  };

  const getBranches = async () => {
    setBranchesLoading(true);
    const data = await branchesService.getAll();

    if (data) {
      setBranches(data.data);
    }
    setBranchesLoading(false);
  };

  useEffect(() => {
    getSettings();
    getBranches();
    getServices();
  }, []);
  return (
    <div>
      <nav className={classes.navbarLayout}>
        <Navbar
          branches={branches}
          branchesLoading={branchesLoading}
          services={services}
          servicesLoading={servicesLoading}
          settings={settings}
        />
      </nav>
      <main>{children}</main>
      <footer>
        <Footer settings={settings} services={services} />
      </footer>
    </div>
  );
};
